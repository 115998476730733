import {getChatConfig} from '@peoplenet/tfm-shared/chat/config'
import {getEldConfig} from '@peoplenet/tfm-shared/eldUi/config'
import {getMioeConfig} from '@peoplenet/tfm-shared/mioeUi/config'
import {getIntegrationsConfigurationConfig} from './integrationsConfiguration/config'
import {getOrgManagementConfig} from '@peoplenet/tfm-shared/orgManagement/config'
import {getRemoteLogoutConfig} from '@peoplenet/tfm-shared/remoteLogout/config'
import {getOEMConfig} from '@peoplenet/tfm-shared/oemGateways/config'
import {getEntitySyncConfig} from './entitySync/config'
import {getNewsFeedConfig} from '@peoplenet/tfm-shared/newsFeed/config'

window.chatEnvUrl = getChatConfig()
window.eldEnvUrl = getEldConfig()
window.mioeEnvUrl = getMioeConfig()
window.integrationsConfigurationEnvUrl = getIntegrationsConfigurationConfig()
window.orgManagementUrl = getOrgManagementConfig()
window.entitySyncEnvUrl = getEntitySyncConfig()
window.remoteLogoutEnvUrl = getRemoteLogoutConfig()
window.oemEnvUrl = getOEMConfig()
window.newsFeedEnvUrl = getNewsFeedConfig()

import('./bootstrap')
