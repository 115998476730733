const getNewsFeedConfig = () => {
    const {REACT_APP_PROD_URL, REACT_APP_LEGACY_PROD_URL} = process.env
    const envRegex = {
        pr: /^https?:\/\/[^/]+-pr\d+/,
        local: /^https?:\/\/localhost:3000/,
        localAlternate: /^https?:\/\/localhost:3001/,
        dev: /^https?:\/\/[^/]+-dev[^/]+\/?/,
        qa: /^https?:\/\/[^/]+-qa[^/]+\/?/,
        staging: /^https?:\/\/[^/]+-staging[^/]+\/?/
    }
    if (REACT_APP_PROD_URL) envRegex.prod = new RegExp(`^https?://(www\\.)?${REACT_APP_PROD_URL.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&')}/?`)
    if (REACT_APP_LEGACY_PROD_URL) envRegex.legacyProd = new RegExp(`^https?://(www\\.)?${REACT_APP_LEGACY_PROD_URL.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&')}/?`)

    const url = window.location.href

    const findKey = (obj, predicate = o => o) => Object.keys(obj)
        .find(key => predicate(obj[key], key, obj))

    let environment = findKey(envRegex, env => env.test(url)) || 'dev'
    if (environment === 'legacyProd') environment = 'prod'

    return {
        local: 'http://localhost:8026',
        qa: 'https://news-feed-ui.qa.platform.connectedfleet.io',
        localAlternate: 'https://news-feed-ui.qa.platform.connectedfleet.io',
        staging: 'https://news-feed-ui.staging.platform.connectedfleet.io'
    }[environment] || 'https://news-feed-ui.platform.fleethealth.io'
}

module.exports = {getNewsFeedConfig}
